import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Hr, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Оренда яхт Olena
			</title>
			<meta name={"description"} content={"Підняти вітрила на розкішному лайнері"} />
			<meta property={"og:title"} content={"Головна | Оренда яхт Olena"} />
			<meta property={"og:description"} content={"Підняти вітрила на розкішному лайнері"} />
			<meta property={"og:image"} content={"https://stellarinfors.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://stellarinfors.com/img/57592346.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://stellarinfors.com/img/57592346.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://stellarinfors.com/img/57592346.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://stellarinfors.com/img/57592346.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://stellarinfors.com/img/57592346.png"} />
			<meta name={"msapplication-TileImage"} content={"https://stellarinfors.com/img/57592346.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://stellarinfors.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Оренда яхт Olena
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Ласкаво просимо до Olena Yacht Charters, де на вас чекає розкішний вітрильний досвід. Наша мета - забезпечити вам незабутню подорож незайманими водами моря. Від романтичних подорожей до сімейних канікул - наш флот преміум-яхт до ваших послуг, готовий втілити ваші мрії про мореплавство в реальність.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 110px 0" quarkly-title="List-1">
			<Text margin="0px 0px 15px 0px" text-align="center" font="normal 600 64px/1.2 --fontFamily-sans" sm-font="normal 600 56px/1.2 --fontFamily-sans">
				Чому обирають нас?
			</Text>
			<Text
				margin="0px 0px 90px 0px"
				text-align="center"
				font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
				color="#5e646a"
				md-margin="0px 0px 60px 0px"
			>
				Компанія Olena Yacht Charters спеціалізується на оренді розкішних яхт, які поєднують в собі елегантність і першокласний сервіс.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 40px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 26px/1.2 --fontFamily-sans">
					Неперевершена якість та сервіс: 
					</Text>
					<Hr
						min-height="10px"
						min-width="100%"
						margin="0px 0px 15px 0px"
						border-width="4px 0 0 0"
						border-style="solid"
						border-color="--color-darkL2"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#5e646a">
					Наші яхти обслуговуються за найвищими стандартами і оснащені найсучаснішими зручностями для забезпечення вашого комфорту і безпеки.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 26px/1.2 --fontFamily-sans">
					Досвідчений екіпаж: 
					</Text>
					<Hr
						min-height="10px"
						min-width="100%"
						margin="0px 0px 15px 0px"
						border-width="4px 0 0 0"
						border-style="solid"
						border-color="--color-darkL2"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#5e646a">
					На кожній яхті працює кваліфікований і досвідчений екіпаж, який ставить на перше місце вашу приватність і задоволення.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 26px/1.2 --fontFamily-sans">
					Індивідуальні враження: 
					</Text>
					<Hr
						min-height="10px"
						min-width="100%"
						margin="0px 0px 15px 0px"
						border-width="4px 0 0 0"
						border-style="solid"
						border-color="--color-darkL2"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#5e646a">
					Від індивідуальних маршрутів до персоналізованих послуг на борту, ми пристосовуємо кожен аспект вашої подорожі до ваших побажань.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 26px/1.2 --fontFamily-sans">
					Конкурентоспроможні ціни: 
					</Text>
					<Hr
						min-height="10px"
						min-width="100%"
						margin="0px 0px 15px 0px"
						border-width="4px 0 0 0"
						border-style="solid"
						border-color="--color-darkL2"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#5e646a">
					Відчуйте розкіш за конкурентними цінами, з прозорими витратами та без прихованих платежів.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-15">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
					margin="0px 0px 35px 0px"
					sm-flex-direction="column"
				>
					<Image
						src="https://stellarinfors.com/img/2.jpg"
						display="block"
						object-fit="cover"
						margin="0px 5px 0px 0px"
						width="26%"
						sm-width="100%"
						sm-margin="0 0 10px 0"
					/>
					<Image
						src="https://stellarinfors.com/img/3.jpg"
						display="block"
						object-fit="cover"
						margin="0px 0px 0px 5px"
						width="37%"
						sm-width="100%"
						sm-margin="0 0 10px 0"
					/>
					<Image
						src="https://stellarinfors.com/img/4.jpg"
						display="block"
						object-fit="cover"
						margin="0px 0px 0px 5px"
						width="37%"
						sm-width="100%"
						sm-margin="0px 0px 0px 0"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 25px 0px"
						font="normal 300 18px/1.5 --fontFamily-sans"
						color="#474e53"
						text-align="left"
						max-width="640px"
					>
						Не зволікайте, щоб відправитися в подорож за розслабленням і пригодами. Зв'яжіться з нами сьогодні, щоб забронювати яхту і створити незабутні спогади з Olena Yacht Charters. Відкрийте для себе мистецтво плавання зі стилем та комфортом.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});